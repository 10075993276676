import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Script from '@gumgum/react-script-tag';

import { ThemeProvider } from '@material-ui/styles';

import 'styles/index.scss';
import theme from 'styles/theme';
import paths from 'config/paths';

import Login from 'components/login/container';
import Register from 'components/register/container';
import Offer from 'components/offer/container';
import Main from 'components/main/container';
import ResetPassword from 'components/reset-password/container';

const App = () => (
  <>
    {/* <Script
      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places,geocoding`}
      type="text/javascript"
    /> */}
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact component={Login} path={paths.login} />
          <Route exact component={Register} path={paths.register} />
          <Route exact component={Offer} path={paths.offer} />
          <Route exact component={Main} path={paths.main} />
          <Route exact component={ResetPassword} path={paths.resetPassword} />

        </Switch>
      </Router>
    </ThemeProvider>
  </>
);

export default App;
