import React, { useReducer } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from 'common/form/button';
import { FieldSize, Color } from 'types/styles';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import TextField from 'common/form/text-field';
import paths from 'config/paths';

// Types

type Props = {
  goToStep: (step: number) => void;
  setLoading: (isLoading: boolean) => void;
};

type FieldValuesState = {
  telephoneNumber: string;
};

type ErrorsState = {
  telephoneNumber: string | null;
};

const Step1 = (props: Props) => {
  const {
    goToStep,
    setLoading,
  } = props;

  const [{
    telephoneNumber,
  }, setFieldValues] = useReducer<BasicReducer<FieldValuesState>>(basicReducer, {
    telephoneNumber: '',
  });

  const [{
    telephoneNumber: telephoneNumberError,
  }, setErrors] = useReducer<BasicReducer<ErrorsState>>(basicReducer, {
    telephoneNumber: 'There is problem with cellphone',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    setTimeout(() => {
      goToStep(2);
    }, 2000);
  };

  const onCaptchaChange = (value) => {
    // console.log(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field-wrapper">
        <TextField
          value={telephoneNumber}
          onChange={(newValue) => setFieldValues({ telephoneNumber: newValue })}
          label="Cell phone number"
          size={FieldSize.fullWidth}
          error={telephoneNumberError}
        />
      </div>

      <div className="field-wrapper center">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
          onChange={onCaptchaChange}
        />
      </div>

      <ul className="reset-password-actions">
        <li>
          <Button color={Color.grey} type="button" to={paths.login} fullWidth>
            Return
          </Button>
        </li>
        <li>
          <Button color={Color.blue} type="submit" fullWidth>
            Submit
          </Button>
        </li>
      </ul>
    </form>
  );
};

export default Step1;
