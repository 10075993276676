import React from 'react';
import classnames from 'classnames';

import loadingGif from 'assets/images/loading.gif';

export type Props = {
  loading: boolean;
  containerClassName?: string;
  children: JSX.Element;
};

const LoadingWrapper = (props: Props) => {
  const {
    loading,
    containerClassName = '',
    children,
  } = props;

  return (
    <div className={classnames('loading-wrapper', { visible: loading })}>
      <div className={classnames('loading-container', { 'full-window': !containerClassName }, containerClassName)}>
        <div className={classnames('loading')}>
          <img src={loadingGif} alt="loading" />
          <p className="label">Please wait...</p>
        </div>
      </div>

      {children}
    </div>
  );
};

LoadingWrapper.defaultProps = {
  containerClassName: '',
};

export default LoadingWrapper;
