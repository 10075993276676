import { createSelector } from 'reselect';

import viewTypeSelector from 'lib/selectors/partials/settings/view-type';
import withMapSelector from 'lib/selectors/partials/settings/with-map';
import gridSizeSelector from 'lib/selectors/partials/settings/grid-size';
import offersDimensionsSelector from 'lib/selectors/partials/layout/dimensions/offers';
import isLoadingOffersSelector from 'lib/selectors/partials/offers/is-loading';

export default createSelector(
  [viewTypeSelector, withMapSelector, gridSizeSelector, offersDimensionsSelector, isLoadingOffersSelector],
  (viewType, withMap, gridSize, offersDimensions, isLoadingOffers) => ({
    viewType,
    withMap,
    gridSize,
    offersDimensions,
    isLoadingOffers,
  }),
);
