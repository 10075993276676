import React, {
  useLayoutEffect,
  useEffect,
  useReducer,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { Offer } from 'types/models';
import { INITIAL_CENTER, INITIAL_ZOOM } from 'config/map';
import { useHoveredOfferContext } from 'lib/contexts/hovered-offer';
import useLayoutRef from 'lib/hooks/use-layout-ref';
import GoogleMapDrawer from 'services/google-map-drawer/google-map-drawer';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import { offersMapSelector } from 'lib/selectors';

import { useStyles } from './styles';

type Props = {
  offers: Offer[];
};

type State = {
  googleMapDrawer: GoogleMapDrawer | null;
  isMapLoaded: boolean;
};

const Map = (props: Props) => {
  const { offers } = props;

  const history = useHistory();
  const [{
    googleMapDrawer,
    isMapLoaded,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    googleMapDrawer: null,
    isMapLoaded: false,
  });
  const mapRef = useLayoutRef('map');
  const {
    range: currentLocationRadius,
    location: currentLocation,
    mapDimensions,
  } = useSelector(offersMapSelector);
  const classes = useStyles({ mapDimensions });
  const { setHoveredOffer, hoveredOffer } = useHoveredOfferContext();

  // Handlers

  const handleMapLoaded = useCallback(() => {
    setState({
      isMapLoaded: true,
    });
  }, []);

  // Side effects (communication between Google Maps Service and React Component)

  // Initial
  useLayoutEffect(() => {
    const initialGoogleMapDrawer = new GoogleMapDrawer(
      {
        initialCenter: INITIAL_CENTER,
        initialZoom: INITIAL_ZOOM,
        history,
        isSingle: false,
        selector: '#google-map-offers',
      },
      {
        mapLoadedCallback: handleMapLoaded,
        setHoveredCallback: setHoveredOffer,
      },
    );

    setState({
      googleMapDrawer: initialGoogleMapDrawer,
    });
  }, []);

  // Updating offers
  useEffect(() => {
    if (!isMapLoaded || !googleMapDrawer) return;

    googleMapDrawer.updateOffers(offers);
  }, [isMapLoaded, offers]);

  // Hovering over
  useEffect(() => { // Hover
    if (!googleMapDrawer) return;

    if (isMapLoaded) googleMapDrawer.setHovered(hoveredOffer);
  }, [isMapLoaded, hoveredOffer]);

  // Showing / Hiding user location
  useEffect(() => {
    if (!googleMapDrawer) return;
    const { latitude, longitude } = currentLocation;

    if (!latitude || !longitude || !currentLocationRadius) {
      googleMapDrawer.hideCurrentLocation();
    } else {
      googleMapDrawer.drawCurrentLocation(latitude, longitude, currentLocationRadius);
    }
  }, [currentLocation, currentLocationRadius, googleMapDrawer]);

  // Render

  return (
    <Grid id="map" item xs={4} className={classes.mapWrapper} ref={mapRef}>
      <div id="google-map-offers" className={classes.map} />
    </Grid>
  );
};

export default Map;
