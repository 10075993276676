import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import { GridSize } from 'types';

type StyleProps = {
  gridSize: GridSize;
  withMap: boolean;
};

const getGridItemWidth = (gridSize, withMap) => {
  switch (gridSize) {
    case GridSize.tiny:
      return withMap ? '20%' : '16.6666%';
    case GridSize.small:
      return withMap ? '25%' : '20%';
    case GridSize.medium:
      return withMap ? '33.3333%' : '25%';
    case GridSize.big:
      return withMap ? '50%' : '33.3333%';
    case GridSize.large:
      return withMap ? '100%' : '50%';
    default:
      return withMap ? '33.3333%' : '25%';
  }
};

export const useWrapperStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const useItemStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  gridItem: {
    width: ({ gridSize, withMap }) => getGridItemWidth(gridSize, withMap),
  },
}));
