import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LayoutActionTypes } from 'store/types/actions';

export default (refName) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: LayoutActionTypes.updateRef,
      payload: {
        refName,
        ref: ref.current,
      },
    });
  }, [ref]);

  return ref;
};
