import { History } from 'history';

import { Offer } from 'types/models';
import { MILES_TO_KILOMETERS } from 'config/map';

import styles from './map-styles';
import OfferOverlay from './overlays/offer';
import CurrentLocationOverlay from './overlays/current-location';

type Callbaks = {
  setHoveredCallback: (offer: Offer) => void;
  mapLoadedCallback: () => void;
};

class GoogleMapDrawer {
  offers: Offer = [];

  callbacks: Callbaks;

  map: google.maps.Map;

  offersOverlays: OfferOverlay[] = [];

  history: History;

  isSingle: boolean;

  currentLocationRadiusCircle;

  currentLocationOverlay!: CurrentLocationOverlay;

  constructor(params, callbacks) {
    this.callbacks = callbacks;

    const {
      initialCenter,
      initialZoom,
      history,
      selector,
      isSingle,
    } = params;

    this.history = history;
    this.isSingle = isSingle;

    const mapOptions = {
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
      styles,
    };

    if (initialCenter) Object.assign(mapOptions, { center: initialCenter });
    if (initialZoom) Object.assign(mapOptions, { zoom: initialZoom });

    this.map = new google.maps.Map(document.querySelector(selector) as HTMLElement, mapOptions);

    google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      const { mapLoadedCallback } = this.callbacks;
      mapLoadedCallback();
    });
  }

  setCallback = (callbackName, callback) => {
    this.callbacks[callbackName] = callback;
  };

  updateOffer = (offer) => {
    const {
      location: {
        lat,
        lng,
      },
    } = offer;

    const overlay = new OfferOverlay({
      offer,
      map: this.map,
      history: this.history,
      callbacks: {},
      isSingle: this.isSingle,
    });

    this.offersOverlays.push(overlay);

    this.map.setZoom(8);
    this.map.panTo(new google.maps.LatLng({ lat, lng }));
    this.map.panBy(0, -100);
  };

  // New offers are actually whole list of current offers to be displayed (not just new batch of them)
  updateOffers = (newOffers) => {
    const bounds = new google.maps.LatLngBounds();
    const { setHoveredCallback } = this.callbacks;

    const offersToRemove: Offer[] = this.offers.filter(({ id }) => !newOffers.find(({ id: newOfferId }) => newOfferId === id));
    const offersToAdd: Offer[] = newOffers.filter(({ id: newOfferId }) => !this.offers.find(({ id }) => newOfferId === id));

    // Add on map

    offersToAdd.forEach((offer) => {
      const overlay = new OfferOverlay({
        offer,
        map: this.map,
        history: this.history,
        callbacks: {
          setHoveredCallback,
        },
        isSingle: this.isSingle,
      });

      this.offersOverlays.push(overlay);
    });

    // Remove from map

    offersToRemove.forEach((offer) => {
      const foundOfferOverlay = this.offersOverlays.find((offerOverlay) => offerOverlay.offer.id === offer.id);

      if (foundOfferOverlay) foundOfferOverlay.setMap(null);
    });

    this.offersOverlays = this.offersOverlays.filter((offerOverlay) => !offersToRemove.find(({ id: offerToRemoveId }) => offerToRemoveId === offerOverlay.offer.id));

    // Bounds

    this.offers = newOffers;

    this.offers.forEach((offer) => {
      const {
        location: {
          lat,
          lng,
        },
      } = offer;

      bounds.extend(new google.maps.LatLng({ lat, lng }));
    });

    this.map.fitBounds(bounds, {
      top: 35,
      bottom: 35,
      left: 35,
      right: 35,
    });
  };

  setHovered = (hoveredOffer) => {
    const hoveredOfferOverlay = this.offersOverlays.find((offerOverlay) => offerOverlay.offer.id === hoveredOffer?.id);

    if (hoveredOfferOverlay) {
      const hoveredOfferMarker = hoveredOfferOverlay?.marker;
      if (hoveredOfferMarker) hoveredOfferMarker.classList.add('hovered');
    }

    this.offersOverlays.filter((offerOverlay) => offerOverlay.offer.id !== hoveredOffer?.id).forEach((offerOverlay) => {
      if (offerOverlay.marker) offerOverlay.marker.classList.remove('hovered');
    });
  };

  drawCurrentLocation = (lat, lng, radius) => {
    this.hideCurrentLocation();

    this.currentLocationRadiusCircle = new google.maps.Circle({
      map: this.map,
      radius: radius * MILES_TO_KILOMETERS * 1000,
      strokeWeight: 0,
      fillColor: '#557083',
      fillOpacity: 0.1,
      center: {
        lat,
        lng,
      },
    });

    this.currentLocationOverlay = new CurrentLocationOverlay({
      lat,
      lng,
      map: this.map,
    });
  };

  hideCurrentLocation = () => {
    if (this.currentLocationRadiusCircle) this.currentLocationRadiusCircle.setMap(null);
    if (this.currentLocationOverlay) this.currentLocationOverlay.setMap(null);
  };
}

export default GoogleMapDrawer;
