import React from 'react';
import { useDispatch } from 'react-redux';

import logo from 'assets/images/logo.png';
import useLayoutRef from 'lib/hooks/use-layout-ref';
import useAuth from 'lib/hooks/use-auth';
import Button from 'common/form/button';
import paths from 'config/paths';
import { Color } from 'types/styles';
import { logout } from 'store/actions/auth';

import { useStyles } from './styles';

const Header = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles({});

  const headerRef = useLayoutRef('header');

  const handleLogout = async () => {
    await dispatch(logout());
  };

  return (
    <div id="header" className={classes.headerWrapper} ref={headerRef}>
      <img src={logo} alt="Truly" />
      {isAuthenticated
        ? <Button color={Color.blue} type="submit" onClick={handleLogout}>Logout</Button>
        : <Button color={Color.blue} to={paths.login}>Login</Button>}
    </div>
  );
};

export default Header;
