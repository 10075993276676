import update from 'immutability-helper';
import { OffersState } from 'store/types/states';
import { OffersActionTypes } from 'store/types/actions';

const initialState = {
  offers: [],
  page: 1,
  lastFetchNoResults: false,
  isLoading: false,
};

function offersReducer(state: OffersState = initialState, action): OffersState {
  switch (action.type) {
    case OffersActionTypes.populate: {
      return {
        offers: action.payload.offers,
        page: action.payload.page,
        lastFetchNoResults: false,
        isLoading: false,
      };
    }

    case OffersActionTypes.updateOne: {
      const updatedOffers = state.offers.map((offer) => {
        if (offer !== action.payload.id) return offer;

        return {
          ...offer,
          ...action.payload,
        };
      });

      return update(state, {
        offers: { $set: updatedOffers },
      });
    }

    case OffersActionTypes.updateLastFetchNoResults: {
      return update(state, {
        lastFetchNoResults: { $set: action.payload.lastFetchNoResults },
        page: { $set: action.payload.page },
        isLoading: { $set: false },
      });
    }

    case OffersActionTypes.updateIsLoading: {
      return update(state, {
        isLoading: { $set: action.payload },
      });
    }

    default:
      return state;
  }
}

export default offersReducer;
