import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Offer } from 'types/models';
import { updateOfferFavourite } from 'store/actions/offers';

import Grid from '@material-ui/core/Grid';
import FavouriteIcon from '@material-ui/icons/Favorite';
import FavouriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { useStyles } from './styles';

type Props = {
  offer: Offer;
  updateOffer: (offer: Offer) => void;
};

const AddToFavourites = (props: Props) => {
  const {
    updateOffer,
    offer,
  } = props;

  const {
    isFavourite,
  } = offer;

  const dispatch = useDispatch();
  const classes = useStyles({});
  const [hovered, setHovered] = useState(false);

  const handleToggleFavourite = () => {
    const { id } = offer;

    const newIsFavourite = !isFavourite;

    Object.assign(offer, {
      isFavourite: newIsFavourite,
    });

    dispatch(updateOfferFavourite(id, newIsFavourite));
    updateOffer(offer);
  };

  return (
    <Grid item xs={5} className="subsection-add-to-favourites">
      <h4 className="section-header">Add to favourites</h4>
      <button
        type="button"
        className={classes.heartWrapper}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleToggleFavourite}
      >
        {(isFavourite || hovered)
          ? (<FavouriteIcon className={classes.heartActive} />)
          : (<FavouriteBorderIcon className={classes.heartInactive} />)}
      </button>
    </Grid>
  );
};

export default memo(AddToFavourites);
