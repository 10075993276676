import categoriesApi from 'api/categories';

export const fetchSearchQueries = async (value) => {
  const { data } = await categoriesApi.fetchCategories(value);

  const first = [data[0], data[1], data[2], data[3]];

  return first.map(({ title }) => title);
};

export const fetchDestinations = async (value) => {
  const { data } = await categoriesApi.fetchCategories(value);

  const first = [data[0], data[1], data[2], data[3]];

  return first.map(({ title, id }) => ({ label: title, value: id }));
};
