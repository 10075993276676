import { Offer } from 'types/models';
import { History } from 'history';
import { getIcon, getGradientColor } from './helpers';

type Callbacks = {
  setHoveredCallback: (offer: Offer) => void;
};

class OfferOverlay extends google.maps.OverlayView {
  bounds;

  map;

  offer: Offer;

  marker;

  history: History;

  callbacks: Callbacks;

  isSingle: boolean;

  constructor(params) {
    super();

    const {
      offer,
      map,
      history,
      isSingle,
      callbacks,
    } = params;

    const {
      location: {
        lat,
        lng,
      },
    } = offer;

    this.bounds = new google.maps.LatLngBounds(new google.maps.LatLng({ lat, lng }), new google.maps.LatLng({ lat, lng }));
    this.map = map;
    this.offer = offer;
    this.history = history;
    this.callbacks = callbacks;
    this.isSingle = isSingle;

    this.setMap(map);
  }

  onAdd() {
    const {
      title,
      isFavourite,
      category,
      cashbackDescription,
      image,
    } = this.offer;

    const icon = getIcon(category);

    const marker = document.createElement('button');
    marker.style.position = 'absolute';
    marker.setAttribute('type', 'button');

    const gradientColor = getGradientColor(isFavourite);

    marker.classList.add('marker', 'offer-marker', `gradient-${gradientColor}`, `category-${category}`); // TODO: Here to change Gradient

    if (this.isSingle) {
      marker.classList.add('single');
    }

    const favouriteIcon = isFavourite ? '<div class="icon-favourite"><i class="material-icons">favorite</i></div>' : '';

    marker.innerHTML = `
      <div class="icon-wrapper">
        ${icon}
        ${favouriteIcon}
      </div>
      <div class="offer-box">
        <div class="image" style="background-image: url('${image}');"></div>
        <p class="title">${title}</p>
        <p class="cashback-description">${cashbackDescription}</p>
      </div>  
    `;

    if (!this.isSingle) {
      marker.addEventListener('click', this.goTo);
      marker.addEventListener('mouseenter', this.setHovered);
      marker.addEventListener('mouseleave', this.unsetHovered);
    }

    this.marker = marker;

    const panes = this.getPanes();
    if (panes) {
      panes.overlayLayer.appendChild(marker);
      panes.overlayMouseTarget.appendChild(marker);
    }
  }

  draw() {
    const overlayProjection = this.getProjection();
    const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
    const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

    this.marker.style.left = `${sw.x}px`;
    this.marker.style.top = `${ne.y}px`;
  }

  // Handlers

  setHovered = () => {
    const { setHoveredCallback } = this.callbacks;
    setHoveredCallback(this.offer);
  };

  unsetHovered = () => {
    const { setHoveredCallback } = this.callbacks;
    setHoveredCallback(null);
  };

  goTo = (event) => {
    event.stopPropagation();
    this.history.push(`/offers/${this.offer.id}`);
  };

  onRemove() {
    this.marker.removeEventListener('click', this.goTo);
    this.marker.removeEventListener('mouseenter', this.setHovered);
    this.marker.removeEventListener('mouseleave', this.unsetHovered);
    this.marker.parentNode.removeChild(this.marker);
    this.marker = null;
  }
}

export default OfferOverlay;
