import { OffersActionTypes, UIActionTypes } from 'store/types/actions';
import offersApi from 'api/offers';

export const updateOfferFavourite = (offerId, newIsFavourite) => async (dispatch) => {
  const { data: updatedOffer } = await offersApi.setOfferFavourite(offerId, newIsFavourite);

  dispatch({
    type: OffersActionTypes.updateOne,
    payload: updatedOffer,
  });
};

// TODO:
// try - catch
// filters
// perPage?
export const fetchOffers = (filters = {}, reset = false) => async (dispatch, getState) => {
  const {
    page: currentPage,
    lastFetchNoResults,
    isLoading,
    offers,
  } = getState().offers;

  const noResultsAndNoClearStart = lastFetchNoResults && !reset;
  if (isLoading || noResultsAndNoClearStart) return;

  dispatch({
    type: OffersActionTypes.updateIsLoading,
    payload: true,
  });

  const newPage = reset ? 1 : currentPage + 1;

  const { data } = await offersApi.fetchOffers(filters, newPage);

  if (data.length) {
    const newOffers = reset ? data : [...offers, ...data];

    dispatch({
      type: OffersActionTypes.populate,
      payload: {
        offers: newOffers,
        page: newPage,
      },
    });

    if (reset) {
      dispatch({
        type: UIActionTypes.updateShouldScrollTop,
        payload: true,
      });
    }
  } else {
    dispatch({
      type: OffersActionTypes.updateLastFetchNoResults,
      payload: {
        page: newPage,
        lastFetchNoResults: true,
      },
    });
  }

  // try {

  // } catch (error) {
  //   console.log(error);
  // }
};
