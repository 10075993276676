import React, { useReducer, useCallback } from 'react';

import logo from 'assets/images/logo.png';
import LoadingWrapper from 'common/loading-wrapper/loading-wrapper';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import useLoggedIn from 'lib/hooks/use-logged-in';

import Step1 from './step-1/step-1';
import Step2 from './step-2/step-2';

type State = {
  isLoading: boolean;
  step: number;
};

const ResetPassword = () => {
  useLoggedIn();
  const backgroundUrl = 'https://images2.alphacoders.com/902/thumb-1920-902946.png'; // TODO: change

  const [{
    isLoading,
    step,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    isLoading: false,
    step: 1,
  });

  const goToStep = useCallback((newStep) => {
    setState({
      step: newStep,
      isLoading: false,
    });
  }, []);

  const setLoading = useCallback((newLoading) => {
    setState({ isLoading: newLoading });
  }, []);

  return (
    <div className="welcome-page reset-password-page">
      <div className="content">
        <div className="inner">
          <a href="http://www.truly360.com" className="logo">
            <img src={logo} alt="Truly" />
          </a>

          <h2 className="title">Reset password</h2>

          <LoadingWrapper loading={isLoading}>
            {step === 1
              ? (
                <Step1
                  goToStep={goToStep}
                  setLoading={setLoading}
                />
              )
              : (
                <Step2
                  goToStep={goToStep}
                  setLoading={setLoading}
                />
              )}
          </LoadingWrapper>
        </div>
      </div>
      <div className="background" style={{ backgroundImage: `url('${backgroundUrl}'` }}>
        <div className="text">
          <h1 className="title">Discover top restaurants, spas, things to do & more</h1>
          <h3 className="subtitle">Check back every day for new deals near you</h3>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
