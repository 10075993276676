import React, {
  memo,
  useRef,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Offer } from 'types/models';
import { GridSize } from 'types';
import paths from 'config/paths';
import { useHoveredOfferContext } from 'lib/contexts/hovered-offer';

import { useItemStyles } from './styles';
import { getGridItemHeight } from './helpers';

// Types

type Props = {
  offer: Offer;
  gridSize: GridSize;
  withMap: boolean;
};

const GridItem = (props: Props) => {
  const {
    offer,
    gridSize,
    withMap,
  } = props;

  const {
    id: offerId,
    title,
    address,
    description,
    cashback,
    cashbackDescription,
    image,
  } = offer;

  const classes = useItemStyles({ gridSize, withMap });
  const [itemHeight, setItemHeight] = useState('');
  const itemRef = useRef(null);
  const { setHoveredOffer, hoveredOffer } = useHoveredOfferContext();

  useEffect(() => {
    if (!itemRef) return;

    setItemHeight(getGridItemHeight(itemRef));
  }, [itemRef, gridSize, withMap]);

  const handleSetHovered = () => {
    setHoveredOffer(offer);
  };

  const handleUnsetHovered = () => {
    setHoveredOffer(null);
  };

  return (
    <li
      className={classnames('grid-item', classes.gridItem, { hovered: hoveredOffer?.id === offerId })}
      ref={itemRef}
      style={{
        height: itemHeight,
        backgroundImage: `url('${image}')`,
      }}
      onMouseEnter={handleSetHovered}
      onMouseLeave={handleUnsetHovered}
    >
      <Link to={paths.offer.replace(':id', offerId)}>
        <h3 className="title-standalone">{title}</h3>
        <div className="cashback-small gradient-orange">
          {/* Gradient diff */}
          {`${cashback}% Cashback`}
        </div>

        <div className="cashback-big gradient-orange">
          {cashbackDescription}
          {/* Fav */}
        </div>
        <div className="full-content">
          <h2 className="title">{title}</h2>
          <p className="address">{address}</p>
          <p className="description">{description}</p>
        </div>
        {/* Add to fav */}
      </Link>
    </li>
  );
};

export default memo(GridItem);
