import update from 'immutability-helper';

import { LocationState } from 'store/types/states';
import { LocationActionTypes } from 'store/types/actions';

const initialState = {
  latitude: null,
  longitude: null,
};

function locationReducer(state: LocationState = initialState, action): LocationState {
  switch (action.type) {
    case LocationActionTypes.update:
      return update(state, {
        latitude: { $set: action.payload.latitude },
        longitude: { $set: action.payload.longitude },
      });
    default:
      return state;
  }
}

export default locationReducer;
