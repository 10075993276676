import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { LayoutActionTypes } from 'store/types/actions';
import layoutRefsSelector from 'lib/selectors/partials/layout/refs';

export default () => {
  const refs = useSelector(layoutRefsSelector);
  const dispatch = useDispatch();

  const calculateDimensions = () => {
    const {
      header,
      filters,
      offers,
    } = refs;

    if (!header) return;

    const headerDimensions = header.getBoundingClientRect();
    const payload = {
      offer: {
        paddingTop: headerDimensions.height,
      },
    };

    if (filters && offers) {
      const filtersDimensions = filters.getBoundingClientRect();

      const headerAndFiltersHeight = headerDimensions.height + filtersDimensions.height;

      Object.assign(payload, {
        filters: {
          top: headerDimensions.height,
        },
        offers: {
          paddingTop: headerAndFiltersHeight,
        },
        map: {
          top: headerAndFiltersHeight,
          height: window.innerHeight - headerAndFiltersHeight,
        },
        loadingForOffers: {
          // top: window.scrollY + (window.innerHeight - headerAndFiltersHeight) / 2,
          top: headerAndFiltersHeight,
          height: window.innerHeight - headerAndFiltersHeight,
        },
      });
    }

    dispatch({
      type: LayoutActionTypes.updateDimensions,
      payload,
    });
  };

  const [debouncedCalculateDimnensions] = useDebouncedCallback(calculateDimensions, 300);

  useEffect(() => {
    window.addEventListener('resize', debouncedCalculateDimnensions);

    return () => window.removeEventListener('resize', debouncedCalculateDimnensions);
  }, []);

  useEffect(() => {
    calculateDimensions();
  }, [refs]);
};
