import update from 'immutability-helper';

import { AuthState } from 'store/types/states';
import { AuthActionTypes } from 'store/types/actions';

const initialState = {
  jwtToken: localStorage.getItem('jwtToken'),
  isAuthenticated: !!localStorage.getItem('jwtToken'),
};

function authReducer(state: AuthState = initialState, action): AuthState {
  switch (action.type) {
    case AuthActionTypes.login:
      return update(state, {
        jwtToken: { $set: action.payload },
        isAuthenticated: { $set: true },
      });
    case AuthActionTypes.logout:
      return update(state, {
        jwtToken: { $set: null },
        isAuthenticated: { $set: false },
      });
    default:
      return state;
  }
}

export default authReducer;
