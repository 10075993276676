import { AxiosResponse } from 'axios';
import axiosInstance from './axios-instance';

const URL = {
  categories: 'https://jsonplaceholder.typicode.com/todos',
};

export default {
  fetchCategories: async (value): Promise<AxiosResponse<any>> => axiosInstance.get(URL.categories, value),
};
