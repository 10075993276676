import React, { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { useStyles } from './styles';

type Props = {
  rating: number;
  votes: number;
};

const RatingWrapper = (props: Props) => {
  const { rating, votes } = props;

  const classes = useStyles({});

  return (
    <Grid item xs={7} className="subsection-rating">
      <h4 className="section-header">Rating</h4>
      <Rating
        defaultValue={rating}
        precision={0.5}
        emptyIcon={<StarBorderIcon />}
        className="rating"
        classes={{
          icon: classes.icon,
        }}
      />
      <h4 className="results">
        <span className="value">{rating}</span>
        <span className="votes">{`(${votes})`}</span>
      </h4>
    </Grid>
  );
};

export default memo(RatingWrapper);
