import { createSelector } from 'reselect';

import offersSelector from 'lib/selectors/partials/offers/offers';
import filtersSelector from 'lib/selectors/partials/filters';

export default createSelector(
  [offersSelector, filtersSelector],
  (offers, filters) => ({
    offers,
    filters,
  }),
);
