export default [
  {
    id: 1,
    title: 'Super oferta',
    address: 'Adres oferty',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.87320,
      lng: 19.70175,
    },
    isFavourite: false,
  },
  {
    id: 2,
    title: 'Super oferta #2',
    address: 'Adres oferty #2',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.77320,
      lng: 18.50175,
    },
    isFavourite: true,
  },
  {
    id: 3,
    title: 'Super oferta #3',
    address: 'Adres oferty #3',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.95320,
      lng: 19.780175,
    },
    isFavourite: false,
  },
  {
    id: 4,
    title: 'Super oferta #4',
    address: 'Adres oferty #4',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 53.127320,
      lng: 18.70175,
    },
    isFavourite: true,
  },
  {
    id: 5,
    title: 'Super oferta #5',
    address: 'Adres oferty #5',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.32320,
      lng: 18.57175,
    },
    isFavourite: false,
  },
  {
    id: 6,
    title: 'Super oferta #6',
    address: 'Adres oferty #6',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.45320,
      lng: 18.84175,
    },
    isFavourite: true,
  },
  {
    id: 7,
    title: 'Super oferta #7',
    address: 'Adres oferty',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 53.77320,
      lng: 17.50175,
    },
    isFavourite: false,
  },
  {
    id: 8,
    title: 'Super oferta #8',
    address: 'Adres oferty #2',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.26320,
      lng: 18.50175,
    },
    isFavourite: true,
  },
  {
    id: 9,
    title: 'Super oferta #9',
    address: 'Adres oferty #3',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.865320,
      lng: 19.780175,
    },
    isFavourite: false,
  },
  {
    id: 10,
    title: 'Super oferta #10',
    address: 'Adres oferty #4',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 53.127320,
      lng: 19.10175,
    },
    isFavourite: true,
  },
  {
    id: 11,
    title: 'Super oferta #11',
    address: 'Adres oferty #5',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.32320,
      lng: 20.57175,
    },
    isFavourite: false,
  },
  {
    id: 12,
    title: 'Super oferta #12',
    address: 'Adres oferty #6',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.425320,
      lng: 17.944175,
    },
    isFavourite: true,
  },
  {
    id: 13,
    title: 'Super oferta #13',
    address: 'Adres oferty',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.487320,
      lng: 19.550175,
    },
    isFavourite: false,
  },
  {
    id: 14,
    title: 'Super oferta #14',
    address: 'Adres oferty #2',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.177320,
      lng: 20.50175,
    },
    isFavourite: true,
  },
  {
    id: 15,
    title: 'Super oferta #15',
    address: 'Adres oferty #3',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.95320,
      lng: 19.1080175,
    },
    isFavourite: false,
  },
  {
    id: 16,
    title: 'Super oferta #16',
    address: 'Adres oferty #4',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.13257320,
      lng: 18.730175,
    },
    isFavourite: true,
  },
  {
    id: 17,
    title: 'Super oferta #17',
    address: 'Adres oferty #5',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.3322320,
      lng: 18.5757175,
    },
    isFavourite: false,
  },
  {
    id: 18,
    title: 'Super oferta #18',
    address: 'Adres oferty #6',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.8845320,
      lng: 19.84175,
    },
    isFavourite: true,
  },
  {
    id: 19,
    title: 'Super oferta #19',
    address: 'Adres oferty #5',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.32320,
      lng: 23.27175,
    },
    isFavourite: false,
  },
  {
    id: 20,
    title: 'Super oferta #20',
    address: 'Adres oferty #6',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.476320,
      lng: 18.86774175,
    },
    isFavourite: true,
  },
  {
    id: 21,
    title: 'Super oferta #21',
    address: 'Adres oferty',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.74353457320,
      lng: 18.53220175,
    },
    isFavourite: false,
  },
  {
    id: 22,
    title: 'Super oferta #22',
    address: 'Adres oferty #2',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.7437320,
      lng: 19.520175,
    },
    isFavourite: true,
  },
  {
    id: 23,
    title: 'Super oferta #23',
    address: 'Adres oferty #3',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 50.65320,
      lng: 20.780175,
    },
    isFavourite: false,
  },
  {
    id: 24,
    title: 'Super oferta #24',
    address: 'Adres oferty #4',
    description: 'Drugi Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 12,
    cashbackDescription: '12% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg',
    telephone: '435 503 032',
    category: 'gym',
    location: {
      lat: 52.327320,
      lng: 19.80175,
    },
    isFavourite: true,
  },
  {
    id: 25,
    title: 'Super oferta #25',
    address: 'Adres oferty #5',
    description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
    cashback: 6,
    cashbackDescription: '23% Cashback of your Entire Bill',
    image: 'https://images.pexels.com/photos/1591447/pexels-photo-1591447.jpeg',
    telephone: '555 503 032',
    category: 'restaurant',
    location: {
      lat: 51.6732320,
      lng: 18.3257175,
    },
    isFavourite: false,
  },
];
