import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { UIActionTypes } from 'store/types/actions';
import shouldScrollTopSelector from 'lib/selectors/partials/ui/should-scroll-top';

export default () => {
  const dispatch = useDispatch();
  const shouldScrollTop = useSelector(shouldScrollTopSelector);

  useEffect(() => {
    if (!shouldScrollTop) return;

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    dispatch({
      type: UIActionTypes.updateShouldScrollTop,
      payload: false,
    });
  }, [shouldScrollTop]);
};
