import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type StyleProps = {
  mapDimensions: {
    top: number;
    height: number;
  };
};

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  mapWrapper: {
    display: 'flex',
    height: ({ mapDimensions }) => mapDimensions?.height,
    top: ({ mapDimensions }) => mapDimensions?.top,
  },
  map: {
    width: '100%',
    height: '100%',
  },
}));
