import React, { useReducer } from 'react';

import Button from 'common/form/button';
import { FieldSize, Color } from 'types/styles';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import TextField from 'common/form/text-field';
import PasswordField from 'common/form/password-field';

// Types

type Props = {
  goToStep: (step: number) => void;
  setLoading: (isLoading: boolean) => void;
};

type FieldValuesState = {
  authenticationCode: string;
  password: string;
  repeatPassword: string;
};

type ErrorsState = {
  authenticationCode: string | null;
  password: string | null;
  repeatPassword: string | null;
};

const Step1 = (props: Props) => {
  const {
    goToStep,
    setLoading,
  } = props;

  const [{
    authenticationCode,
    password,
    repeatPassword,
  }, setFieldValues] = useReducer<BasicReducer<FieldValuesState>>(basicReducer, {
    authenticationCode: '',
    password: '',
    repeatPassword: '',
  });

  const [{
    authenticationCode: authenticationCodeError,
    password: passwordError,
    repeatPassword: repeatPasswordError,
  }, setErrors] = useReducer<BasicReducer<ErrorsState>>(basicReducer, {
    authenticationCode: 'There is problem with cellphone',
    password: '',
    repeatPassword: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    setTimeout(() => {
      goToStep(2);
    }, 2000);
  };

  const handleGoBack = () => {
    goToStep(1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field-wrapper">
        <TextField
          value={authenticationCode}
          onChange={(newValue) => setFieldValues({ authenticationCode: newValue })}
          label="Cell phone number"
          bottomLabel="Code sent via SMS"
          size={FieldSize.fullWidth}
          error={authenticationCodeError}
        />
      </div>

      <div className="field-wrapper">
        <PasswordField
          value={password}
          onChange={(newValue) => setFieldValues({ password: newValue })}
          label="New password"
          size={FieldSize.fullWidth}
          error={passwordError}
        />
      </div>

      <div className="field-wrapper">
        <PasswordField
          value={repeatPassword}
          onChange={(newValue) => setFieldValues({ repeatPassword: newValue })}
          label="Confirm new password"
          size={FieldSize.fullWidth}
          error={repeatPasswordError}
        />
      </div>

      <ul className="reset-password-actions">
        <li>
          <Button color={Color.grey} type="button" fullWidth onClick={handleGoBack}>
            Return
          </Button>
        </li>
        <li>
          <Button color={Color.blue} type="submit" fullWidth>
            Reset password
          </Button>
        </li>
      </ul>
    </form>
  );
};

export default Step1;
