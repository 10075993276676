import React from 'react';
import { useSelector } from 'react-redux';

import loadingForOffersSelector from 'lib/selectors/partials/layout/dimensions/loading-for-offers';

import LoadingWrapper, { Props } from './loading-wrapper';
import { useLoadingForOffersStyles } from './styles';

const withForOffers = (WrappedComponent) => (props: Props) => {
  const loadingDimensions = useSelector(loadingForOffersSelector);

  const classes = useLoadingForOffersStyles({ loadingDimensions });

  return (
    <WrappedComponent {...props} containerClassName={classes.loadingContainer} />
  );
};

export default withForOffers(LoadingWrapper);
