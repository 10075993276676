import usersApi from 'api/users';
import { AuthActionTypes } from 'store/types/actions';

export const login = (telephone, password) => async (dispatch) => {
  try {
    const { data: jwtToken } = await usersApi.login(telephone, password);

    dispatch({
      type: AuthActionTypes.login,
      payload: jwtToken,
    });

    localStorage.setItem('jwtToken', jwtToken);
  } catch (error) {
    console.log(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    await usersApi.logout();

    dispatch({
      type: AuthActionTypes.logout,
    });

    localStorage.removeItem('jwtToken');
  } catch (error) {
    
  }
};
