import React from 'react';
import classnames from 'classnames';

import { FieldSize } from 'types/styles';

// Types

type Props = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  bottomLabel?: string;
  size: FieldSize;
  error: string | null;
};

const TextField = (props: Props) => {
  const {
    value,
    onChange,
    label,
    bottomLabel = '',
    size,
    error,
  } = props;

  const handleChange = (event) => {
    onChange(event.target.noValueLabel);
  };

  return (
    <div className={classnames('field-common', 'text-field', size, { 'is-error': !!error })}>
      <p className="label">{label}</p>

      <div className="field">
        <input
          type="text"
          value={value}
          onChange={handleChange}
        />

        {(error || bottomLabel) && (
          <div className="field-bottom">
            {error && <h6 className="error">{error}</h6>}
            {bottomLabel && <h6 className="bottom-label">{bottomLabel}</h6>}
          </div>
        )}
      </div>
    </div>
  );
};

TextField.defaultProps = {
  bottomLabel: '',
};

export default TextField;
