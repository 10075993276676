import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        color: '#7f7f7f',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#0098fe',
        '&$checked': {
          color: '#0098fe !important',
        },
        '&$indeterminate': {
          color: 'green !important',
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 152, 254, 0.05) !important',
        },
      },
    },
    MuiSlider: {
      thumb: {
        transform: 'translateY(0px)',
        width: '16px',
        height: '16px',
        boxSizing: 'border-box',
        border: '1px solid #009aff',
        background: 'white',
        color: 'transparent',
        position: 'relative',
        '&:after': {
          display: 'block',
          content: '',
          width: '10px',
          height: '10px',
          background: '#009aff',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
      track: {
        color: '#eaefef',
        height: '5px',
        borderRadius: '4px',
      },
      rail: {
        color: '#eaefef',
        height: '5px',
      },
      mark: {
        backgroundColor: '#cfe6f0',
        width: '5px',
        height: '5px',
        borderRadius: '4px',
      },
      markActive: {
        background: '#009aff !important',
      },
    },
  },
});

export default theme;
