import React from 'react';

import { Offer } from 'types/models';

import AddToFavourites from './add-to-favourites/add-to-favourites';
import Contact from './contact/contact';
import Map from './map/map';
import Rating from './rating/rating';
import OpeningHours from './opening-hours/opening-hours';

type Props = {
  offer: Offer;
  updateOffer: (offer: Offer) => void;
};

const OfferContent = (props: Props) => {
  const {
    offer,
    offer: {
      title,
      website,
      telephone,
      address,
      cashbackDescription,
      description,
      rating,
      votes,
      openingHours,
    },
    updateOffer,
  } = props;

  return (
    <>
      <div className="cashback-information gradient-light-blue">
        <h3 className="text">
          <span className="icon icon-tag" />
          {cashbackDescription}
        </h3>
      </div>
      <div className="offer-section header">
        <h1 className="title">{title}</h1>

        <p>{description}</p>
      </div>
      <div className="offer-section rating-and-favourite">
        <Rating rating={rating} votes={votes} />
        <AddToFavourites offer={offer} updateOffer={updateOffer} />
      </div>
      <div className="offer-section contact-opening-hours">
        <Contact address={address} telephone={telephone} website={website} />
        <OpeningHours openingHours={openingHours} />
      </div>
      <Map offer={offer} />
    </>
  );
};

export default OfferContent;
