import update from 'immutability-helper';

import { FiltersState } from 'store/types/states';
import { FiltersActionTypes } from 'store/types/actions';

const initialState = {
  searchQuery: '',
  range: null,
  destination: null,
  cashback: '',
  type: '',
};

function filtersReducer(state: FiltersState = initialState, action): FiltersState {
  switch (action.type) {
    case FiltersActionTypes.updateOne:
      return update(state, {
        [action.payload.filter]: { $set: action.payload.value },
      });
    case FiltersActionTypes.updateAll:
      return action.payload;
    default:
      return state;
  }
}

export default filtersReducer;
