import React, {
  memo,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { FiltersState } from 'store/types/states';
import { FiltersActionTypes, LocationActionTypes } from 'store/types/actions';
import useLayoutRef from 'lib/hooks/use-layout-ref';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import SelectField from 'common/form/select-field';
import SearchSelectField from 'common/form/search-select-field';
import Button from 'common/form/button';
import LayoutWrapper from 'common/layout-wrapper/layout-wrapper';
import { Color, FieldSize } from 'types/styles';
import { filtersDimensionsAndFiltersSelector } from 'lib/selectors';

import { useStyles } from './styles';
import { fetchSearchQueries, fetchDestinations } from './fetches';

// Types

const rangeOptions = [
  {
    label: '25 mi',
    value: 25,
  },
  {
    label: '50 mi',
    value: 50,
  },
  {
    label: '75 mi',
    value: 75,
  },
  {
    label: '100 mi',
    value: 100,
  },
];

const cashbackOptions = [
  {
    label: '3%',
    value: 3,
  },
  {
    label: '6%',
    value: 6,
  },
  {
    label: '10%',
    value: 10,
  },
];

const typeOptions = [
  {
    label: 'Type #1',
    value: 'type-1',
  },
  {
    label: 'Type #2',
    value: 'type-2',
  },
];

type FiltersLocalState = FiltersState;

const Filters = () => {
  const [localFilters, setLocalFilters] = useReducer<BasicReducer<FiltersLocalState>>(basicReducer, {
    searchQuery: '',
    range: 25,
    destination: null,
    cashback: '',
    type: '',
  });

  const {
    searchQuery,
    range,
    destination,
    cashback,
    type,
  } = localFilters;

  const dispatch = useDispatch();
  const {
    filtersDimensions,
    filters: savedFilters,
  } = useSelector(filtersDimensionsAndFiltersSelector);

  const classes = useStyles({ filtersDimensions });
  const filtersRef = useLayoutRef('filters');

  // Handlers

  const handleChange = useCallback((name, value) => {
    setLocalFilters({
      [name]: value,
    });
  }, []);

  const handleSubmitFilters = () => {
    dispatch({
      type: FiltersActionTypes.updateAll,
      payload: localFilters,
    });
  };

  const getLocationSuccess = (position) => {
    const {
      coords: {
        // accuracy,
        latitude,
        longitude,
      },
    } = position;

    dispatch({
      type: LocationActionTypes.update,
      payload: {
        latitude,
        longitude,
      },
    });
  };

  // Side effects

  // Updating local filters on new search
  useEffect(() => {
    setLocalFilters(savedFilters);

    if (savedFilters.destination === 'current-location') {
      navigator.geolocation.getCurrentPosition(getLocationSuccess, (error) => { console.log(error); });
    } else {
      dispatch({
        type: LocationActionTypes.update,
        payload: {
          latitude: null,
          longitude: null,
        },
      });
    }
  }, [savedFilters]);

  // Render

  return (
    <div id="filters" className={classes.filtersWrapper} ref={filtersRef}>
      <LayoutWrapper>
        <Grid container justify="space-between">
          <Grid item className={classes.filtersContainer}>
            <Grid item className={classes.filterField}>
              <SearchSelectField
                size={FieldSize.long}
                label="What are you looking for"
                noValueLabel="Start typying..."
                name="searchQuery"
                value={searchQuery}
                onChange={handleChange}
                fetchOptionsMethod={fetchSearchQueries}
                // withArrow
                isTextValue
              />
            </Grid>
            <Grid item className={classes.filterField}>
              <SearchSelectField
                size={FieldSize.long}
                label="Destination"
                noValueLabel="Start typying..."
                currentLocationLabel="My current location"
                name="destination"
                value={destination}
                onChange={handleChange}
                fetchOptionsMethod={fetchDestinations} // TODO: CHANGE
                isDestination
              />
            </Grid>
            {destination === 'current-location' && (
              <Grid item className={classes.filterField}>
                <SelectField
                  size={FieldSize.short}
                  label="Set range"
                  noValueLabel="Range..."
                  options={rangeOptions}
                  name="range"
                  value={range}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item className={classes.filterField}>
              <SelectField
                size={FieldSize.medium}
                label="Cashback"
                noValueLabel="Choose..."
                options={cashbackOptions}
                name="cashback"
                value={cashback}
                onChange={handleChange}
                withArrow
                icon="tag"
              />
            </Grid>
            <Grid item className={classes.filterField}>
              <SelectField
                size={FieldSize.medium}
                label="Type"
                noValueLabel="Choose..."
                options={typeOptions}
                name="type"
                value={type}
                onChange={handleChange}
                withArrow
                icon="bag"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button color={Color.blue} onClick={handleSubmitFilters}>Search</Button>
          </Grid>
        </Grid>
      </LayoutWrapper>
    </div>
  );
};

export default memo(Filters);
