import React, { memo, useLayoutEffect, useEffect, useReducer, useCallback } from 'react';

import { Offer } from 'types/models';
import GoogleMapDrawer from 'services/google-map-drawer/google-map-drawer';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';

// Types

type Props = {
  offer: Offer;
};

type State = {
  googleMapDrawer: GoogleMapDrawer | null;
  isMapLoaded: boolean;
};

const Map = (props: Props) => {
  const { offer } = props;

  const [{
    googleMapDrawer,
    isMapLoaded,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    googleMapDrawer: null,
    isMapLoaded: false,
  });

  const handleMapLoaded = useCallback(() => {
    setState({
      isMapLoaded: true,
    });
  }, []);

  useEffect(() => {
    if (!isMapLoaded || !googleMapDrawer) return;

    googleMapDrawer.updateOffer(offer);
  }, [isMapLoaded, offer]);

  useLayoutEffect(() => {
    const initialGoogleMapDrawer = new GoogleMapDrawer(
      {
        isSingle: true,
        selector: '#offer-map',
      },
      {
        mapLoadedCallback: handleMapLoaded,
      },
    );

    setState({
      googleMapDrawer: initialGoogleMapDrawer,
    });
  }, []);

  return (
    <div id="offer-map" />
  );
};

export default memo(Map);
