import React from 'react';

import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import {
  sliderMarks,
} from './helpers';
import { useSliderStyles } from './styles';

// Types

type Props = {
  title: string;
  value: number;
  onChange: (event: any, newValue: number | number[]) => void;
};

const SliderWrapper = (props: Props) => {
  const {
    title,
    value,
    onChange,
  } = props;

  const classes = useSliderStyles({});

  return (
    <Grid item xs={2} className={classes.sliderWrapper}>
      <p className={classes.sliderLabel}>{title}</p>
      <Slider
        value={value}
        step={1}
        marks={sliderMarks}
        min={0}
        max={4}
        onChange={onChange}
      />
    </Grid>
  );
};

export default SliderWrapper;
