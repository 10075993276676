import React from 'react';
import { useStyles } from './styles';

// Types

type Props = {
  children: JSX.Element;
};

const LayoutWrapper = (props: Props) => {
  const { children } = props;

  const classes = useStyles({});

  return (
    <div className={classes.layoutWrapper}>
      {children}
    </div>
  );
};

export default LayoutWrapper;
