import { combineReducers } from 'redux';

import authReducer from './reducers/auth';
import settingsReducer from './reducers/settings';
import layoutReducer from './reducers/layout';
import offersReducer from './reducers/offers';
import filtersReducer from './reducers/filters';
import locationReducer from './reducers/location';
import uiReducer from './reducers/ui';

export default combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  layout: layoutReducer,
  offers: offersReducer,
  filters: filtersReducer,
  location: locationReducer,
  ui: uiReducer,
});
