import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  viewSettingsWrapper: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  viewSettingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mapLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mapIcon: {
    color: '#c4d4d3',
    fontSize: '1.5em',
    marginRight: theme.spacing(2),
    display: 'block',
  },
}));

export const useSliderStyles = makeStyles((theme: Theme) => createStyles({
  sliderWrapper: {
    paddingLeft: theme.spacing(3),
  },
  sliderLabel: {
    color: '#b9b9b9',
  },
}));
