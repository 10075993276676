import update from 'immutability-helper';

import { UIState } from 'store/types/states';
import { UIActionTypes } from 'store/types/actions';

const initialState = {
  shouldScrollTop: false,
};

function UIReducer(state: UIState = initialState, action): UIState {
  switch (action.type) {
    case UIActionTypes.updateShouldScrollTop:
      return update(state, {
        shouldScrollTop: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default UIReducer;
