import { createSelector } from 'reselect';

import rangeSelector from 'lib/selectors/partials/filters/range';
import locationSelector from 'lib/selectors/partials/location';
import mapDimensionsSelector from 'lib/selectors/partials/layout/dimensions/map';

export default createSelector(
  [rangeSelector, locationSelector, mapDimensionsSelector],
  (range, location, mapDimensions) => ({
    range,
    location,
    mapDimensions,
  }),
);
