import React from 'react';
import classnames from 'classnames';

type Props = {
  icon?: string;
  IconSVG?: React.FC<React.SVGProps<SVGSVGElement>>;
  isActive?: boolean;
  onClick: () => void;
};

const IconButton = (props: Props) => {
  const {
    icon,
    IconSVG,
    isActive = false,
    onClick = () => {},
  } = props;

  return (
    <button
      type="button"
      className={classnames('icon-button', { active: isActive })}
      onClick={onClick}
    >
      {icon && (<span className={classnames('icon', `icon-${icon}`)} />)}
      {IconSVG && (<IconSVG color={isActive ? '#0098fe' : '#c4d4d3'} />)}
    </button>
  );
};

IconButton.defaultProps = {
  icon: '',
  IconSVG: null,
  isActive: false,
};

export default IconButton;
