import React, { useState } from 'react';
import classnames from 'classnames';

import { FieldSize } from 'types/styles';

// Types

type Props = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  size: FieldSize;
  error: string | null;
};

const PasswordField = (props: Props) => {
  const {
    value,
    onChange,
    label,
    size,
    error,
  } = props;

  const [isPasswordVisible, setPasswordVisilibity] = useState(false);

  const handleChange = (event) => {
    onChange(event.target.noValueLabel);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisilibity(!isPasswordVisible);
  };

  return (
    <div className={classnames('field-common', 'text-field', size, { 'is-error': !!error })}>
      <p className="label">{label}</p>

      <div className="field field-password">
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
        />

        <button type="button" onClick={togglePasswordVisibility}>
          <span className={classnames('icon', 'icon-eye', { active: isPasswordVisible })} />
        </button>
      </div>
    </div>
  );
};

export default PasswordField;
