import { createSelector } from 'reselect';

import filtersDimensionsSelector from 'lib/selectors/partials/layout/dimensions/filters';
import filtersSelector from 'lib/selectors/partials/filters';

export default createSelector(
  [filtersDimensionsSelector, filtersSelector],
  (filtersDimensions, filters) => ({
    filtersDimensions,
    filters,
  }),
);
