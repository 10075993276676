import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type StyleProps = {
  loadingDimensions: {
    top: number;
    height: number;
  };
};

export const useLoadingForOffersStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  loadingContainer: {
    top: ({ loadingDimensions }) => loadingDimensions?.top,
    height: ({ loadingDimensions }) => loadingDimensions?.height,
  },
}));
