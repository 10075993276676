import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useLayoutDimensions from 'lib/hooks/use-layout-dimensions';
import { fetchOffers } from 'store/actions/offers';
import { offersAndFiltersSelector } from 'lib/selectors';

import Header from 'common/header/header';

import Filters from './filters/filters';
import Offers from './offers/offers';

// Types

const Main = () => {
  useLayoutDimensions();

  const dispatch = useDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const {
    offers,
    filters,
    // perPage,
  } = useSelector(offersAndFiltersSelector);

  useEffect(() => {
    if (isFirstRender) {
      if (!offers.length) {
        dispatch(fetchOffers(filters, true));
      }

      setIsFirstRender(false);
    } else {
      dispatch(fetchOffers(filters, true));
    }
  }, [filters]);

  return (
    <>
      <Header />
      <Filters />
      <Offers offers={offers} />
    </>
  );
};

export default Main;
