import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Offer } from 'types/models';
import paths from 'config/paths';
import offerDimensionsSelector from 'lib/selectors/partials/layout/dimensions/offer';

import { useStyles } from './styles';

type Props = {
  loading: boolean;
  offer: Offer;
  children: JSX.Element;
};

const Background = (props: Props) => {
  const {
    loading,
    offer,
    children,
  } = props;

  const offerDimensions = useSelector(offerDimensionsSelector);
  const classes = useStyles({ offerDimensions });

  if (!offer) {
    return (
      <div className="offer-background">
        {children}
      </div>
    ); // TODO:
  }

  const {
    logo,
    image, // TODO: background
  } = offer;

  return (
    <div className={classnames('offer-background', classes.backgroundWrapper)}>
      <div className="offer-background-inner" style={{ backgroundImage: `url('${image}')` }}>
        <Link to={paths.main}>
          <div className="come-back">
            <span className="icon icon-arrow-left" />
          </div>
        </Link>
        <div className="logo-wrapper">
          <img src={logo} alt="" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(Background);
