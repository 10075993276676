import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import Grid from '@material-ui/core/Grid';

import { Offer } from 'types/models';
import { fetchOffers } from 'store/actions/offers';
import { HoveredOfferContext } from 'lib/contexts/hovered-offer';
import useLayoutRef from 'lib/hooks/use-layout-ref';
import useUIScrollToTop from 'lib/hooks/use-ui-scroll-to-top';
import { ViewType } from 'types';
import { offersRouteSelector } from 'lib/selectors';
import LoadingWrapperForOffers from 'common/loading-wrapper/loading-wrapper-for-offers';

import { useStyles } from './styles';
import ViewSettings from './view-settings/view-settings';
import Map from './map/map';

import GridView from './views/grid/grid';
import ListView from './views/list/list';

// Types

type Props = {
  offers: Offer[];
};

const Offers = (props: Props) => {
  const { offers } = props;

  useUIScrollToTop();
  const dispatch = useDispatch();
  const {
    withMap,
    viewType,
    gridSize,
    offersDimensions,
    isLoadingOffers,
  } = useSelector(offersRouteSelector);
  const offersRef = useLayoutRef('offers');
  const classes = useStyles({ offersDimensions });
  const [hoveredOffer, setHoveredOffer] = useState(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scrollY, setScrollY] = useState(window.scrollY);

  const handleScroll = () => {
    setScrollY(window.scrollY);
    if (window.scrollY < scrollY) return; // Only scrolling down is accepted
    if (!wrapperRef || !wrapperRef.current) return;

    const wrapperDimensions = wrapperRef.current.getBoundingClientRect();

    if (window.scrollY + window.innerHeight > wrapperDimensions.height - 100) {
      dispatch(fetchOffers());
    }
  };

  const [debouncedHandleScroll] = useDebouncedCallback(handleScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  return (
    <HoveredOfferContext.Provider
      value={{
        hoveredOffer,
        setHoveredOffer,
      }}
    >
      <LoadingWrapperForOffers loading={isLoadingOffers}>
        <Grid container ref={offersRef} className={classes.offersWrapper}>
          <Grid item xs={withMap ? 8 : 12} ref={wrapperRef}>
            <ViewSettings
              viewType={viewType}
              withMap={withMap}
              gridSize={gridSize}
            />

            {viewType === ViewType.grid && <GridView offers={offers} gridSize={gridSize} withMap={withMap} />}
            {viewType === ViewType.list && <ListView offers={offers} />}
          </Grid>

          {withMap && (
            <Map offers={offers} />
          )}
        </Grid>
      </LoadingWrapperForOffers>
    </HoveredOfferContext.Provider>
  );
};

export default Offers;
