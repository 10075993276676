import React, { useReducer } from 'react';
import classnames from 'classnames';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { FieldSize } from 'types/styles';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';

type OptionValue = number | string | null;

type Props = {
  label: string;
  noValueLabel: string;
  options: {
    label: string;
    value: string | number;
  }[];
  name: string;
  value: OptionValue
  onChange: (name: string, value: OptionValue) => void;
  size: FieldSize;
  withArrow?: boolean;
  icon?: string;
};
type State = {
  areOptionsVisible: boolean;
};

const SelectField = (props: Props) => {
  const {
    label,
    noValueLabel,
    options,
    name,
    value,
    onChange,
    size,
    withArrow = false,
    icon = '',
  } = props;

  const [{
    areOptionsVisible,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    areOptionsVisible: false,
  });

  const toggleShowOptions = () => {
    setState({
      areOptionsVisible: !areOptionsVisible,
    });
  };

  const handleChange = (event, optionValue) => {
    if (optionValue === value) {
      event.stopPropagation();
      return;
    }

    onChange(name, optionValue);
  };

  const handleClose = () => {
    setState({
      areOptionsVisible: false,
    });
  };

  const currentOptionLabel = options.find(({ value: optionValue }) => value === optionValue)?.label || noValueLabel;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <button type="button" className={classnames('field-common', 'select-field', size, { choosen: !!value })} onClick={toggleShowOptions}>
        <p className="label">{label}</p>
        <h5 className="current-value">
          <span className="text">
            {icon && (
              <span className={classnames('icon-on-left', `icon-${icon}`)} />
            )}
            {currentOptionLabel}
          </span>
          {withArrow && (
            <span className={classnames('icon', `icon-arrow-${areOptionsVisible ? 'up' : 'down'}`)} />
          )}
        </h5>

        {areOptionsVisible && (
          <div className="options-wrapper">
            <ul className="options">
              {options.map((option) => (
                <li key={value} className={classnames('option', { current: option.value === value })}>
                  <button type="button" onClick={(event) => handleChange(event, option.value)} className="option-button">
                    {option.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </button>
    </ClickAwayListener>
  );
};

SelectField.defaultProps = {
  withArrow: false,
  icon: '',
};

export default SelectField;
