import React, { memo } from 'react';
import classnames from 'classnames';

import { Offer } from 'types/models';
import { GridSize } from 'types';

import { useWrapperStyles } from './styles';
import GridItem from './item';

// Types

type Props = {
  offers: Offer[];
  gridSize: GridSize;
  withMap: boolean;
};

const Grid = (props: Props) => {
  const {
    offers,
    gridSize,
    withMap,
  } = props;

  const classes = useWrapperStyles();

  return (
    <ul className={classnames('offers', 'grid-view', classes.wrapper)}>
      {offers.map((offer) => (
        <GridItem key={offer.id} offer={offer} gridSize={gridSize} withMap={withMap} />
      ))}
    </ul>
  );
};

export default memo(Grid);
