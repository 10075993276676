import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type StyleProps = {
  filtersDimensions: {
    top: number;
  };
};

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  filtersWrapper: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    borderTop: '1px solid #f2f6f6',
    borderBottom: '1px solid #f2f6f6',
    background: 'white',
    top: ({ filtersDimensions }) => filtersDimensions?.top || 0,
  },
  filtersContainer: {
    display: 'flex',
  },
  filterField: {
    marginRight: theme.spacing(1),
  },
}));
