import axios, { AxiosRequestConfig } from 'axios';
// import qs from 'qs';

const axiosInstance = axios.create();

export default {
  get: async (url: string, params?, options?) => axiosInstance.get(url, {
    params,
    ...options,
  }),
  post: async (url, payload?: any) => axiosInstance.post(url, payload),
  patch: async (url: string, payload?: any) => axiosInstance.patch(url, payload),
  delete: async (url: string, config?: AxiosRequestConfig) => axiosInstance.delete(url, config),
};
