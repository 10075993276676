import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type StyleProps = {
  offersDimensions: {
    paddingTop: number;
  };
};

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  offersWrapper: {
    paddingTop: ({ offersDimensions }) => offersDimensions?.paddingTop || 0,
  },
}));
