export const getIcon = (category) => {
  switch (category) {
    case 'restaurant':
      return '<i class="material-icons icon">restaurant</i>';
    case 'gym':
      return '<i class="material-icons icon">face</i>';
    default:
      break;
  }
};

export const getGradientColor = (isFavourite) => (isFavourite ? 'pink' : 'light-blue');
