import React, { useReducer, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Offer } from 'types/models';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import Header from 'common/header/header';
import offersApi from 'api/offers';
import useLayoutDimensions from 'lib/hooks/use-layout-dimensions';

import Background from './background/background';
import OfferContent from './offer-content';

// Types

interface QueryParams {
  id: string;
  assetId: string;
}

type Props = RouteComponentProps<QueryParams>;
type State = {
  loading: boolean;
  offer: Offer;
};

const OfferRoute = (props: Props) => {
  useLayoutDimensions();

  const {
    match: {
      params: {
        id: offerId,
      },
    },
  } = props;

  const [{
    loading,
    offer,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    loading: false,
    offer: null,
  });

  const fetchOffer = useCallback(async () => {
    setState({ loading: true });
    try {
      const { data } = await offersApi.fetchOffer(offerId);

      setState({
        loading: false,
        offer: data,
      });
    } catch (error) {
      setState({ loading: false });
    }
  }, [offerId]);

  const updateOffer = useCallback((updatedOffer) => {
    setState({
      offer: updatedOffer,
    });
  }, []);

  useEffect(() => {
    fetchOffer();
  }, [fetchOffer]);

  return (
    <>
      <Header />
      <Background offer={offer} loading={loading}>
        <div className="offer-full-content">
          {loading && (
            <p>Loading</p>
          )}
          {offer && <OfferContent offer={offer} updateOffer={updateOffer} />}
        </div>
      </Background>
    </>
  );
};

export default OfferRoute;
