import React from 'react';

type Props = {
  label: string;
};

const Separator = (props: Props) => {
  const { label } = props;

  return (
    <div className="separator">
      <h5 className="text">{label}</h5>
    </div>
  );
};

export default Separator;
