import React, { useReducer } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import logo from 'assets/images/logo.png';

import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import TextField from 'common/form/text-field';
import PasswordField from 'common/form/password-field';
import Button from 'common/form/button';
import Separator from 'common/content/separator';
import { FieldSize, Color } from 'types/styles';
import paths from 'config/paths';
import useLoggedIn from 'lib/hooks/use-logged-in';

// Types

type FieldValuesState = {
  telephoneNumber: string;
  repeatTelephoneNumber: string;
  password: string;
  repeatPassword: string;
  confirmTermsOfUse: boolean;
};

type ErrorsState = {
  telephoneNumber: string | null;
  repeatTelephoneNumber: string | null;
  password: string | null;
  repeatPassword: string | null;
};

const Register = () => {
  useLoggedIn();

  const [{
    telephoneNumber: telephoneNumberError,
    repeatTelephoneNumber: repeatTelephoneNumberError,
    password: passwordError,
    repeatPassword: repeatPasswordError,
  }, setErrors] = useReducer<BasicReducer<ErrorsState>>(basicReducer, {
    telephoneNumber: 'There is problem with cellphone',
    repeatTelephoneNumber: '',
    password: '',
    repeatPassword: '',
  });

  const [{
    telephoneNumber,
    repeatTelephoneNumber,
    password,
    repeatPassword,
    confirmTermsOfUse,
  }, setFieldValues] = useReducer<BasicReducer<FieldValuesState>>(basicReducer, {
    telephoneNumber: '',
    repeatTelephoneNumber: '',
    password: '',
    repeatPassword: '',
    confirmTermsOfUse: false,
  });

  const onCaptchaChange = (value) => {
    console.log(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log('Wysylamy');
  };

  const backgroundUrl = 'https://images2.alphacoders.com/902/thumb-1920-902946.png'; // TODO: change

  return (
    <div className="welcome-page login-page">
      <div className="content">
        <div className="inner">
          <a href="http://www.truly360.com" className="logo">
            <img src={logo} alt="Truly" />
          </a>

          <h2 className="title">Create an account</h2>

          <form onSubmit={handleSubmit}>
            <div className="field-wrapper">
              <TextField
                value={telephoneNumber}
                onChange={(newValue) => setFieldValues({ telephoneNumber: newValue })}
                label="Cell phone number"
                size={FieldSize.fullWidth}
                error={telephoneNumberError}
              />
            </div>

            <div className="field-wrapper">
              <TextField
                value={repeatTelephoneNumber}
                onChange={(newValue) => setFieldValues({ repeatTelephoneNumber: newValue })}
                label="Confirm Cell phone number"
                size={FieldSize.fullWidth}
                error={repeatTelephoneNumberError}
              />
            </div>

            <div className="field-wrapper">
              <PasswordField
                value={password}
                onChange={(newValue) => setFieldValues({ password: newValue })}
                label="Password"
                size={FieldSize.fullWidth}
                error={passwordError}
              />
            </div>

            <div className="field-wrapper">
              <PasswordField
                value={repeatPassword}
                onChange={(newValue) => setFieldValues({ repeatPassword: newValue })}
                label="Confirm password"
                size={FieldSize.fullWidth}
                error={repeatPasswordError}
              />
            </div>

            <div className="field-wrapper">
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={confirmTermsOfUse}
                    onChange={(event) => setFieldValues({ confirmTermsOfUse: event.target.checked })}
                    name="confirmTermsOfUse"
                  />
                )}
                label={(
                  <p>
                    I agree to Truly360
                    <Link to={paths.termsOfUse}>Terms of Use</Link>
                  </p>
                )}
              />
            </div>

            <div className="field-wrapper center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                onChange={onCaptchaChange}
              />
            </div>

            <div className="login-action">
              <Button color={Color.blue} type="submit" fullWidth>
                Create an account
              </Button>
              <Separator label="or" />
              <Button color={Color.grey} type="button" to={paths.login} fullWidth>
                Sign in
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="background" style={{ backgroundImage: `url('${backgroundUrl}'` }}>
        <div className="text">
          <h1 className="title">Discover top restaurants, spas, things to do & more</h1>
          <h3 className="subtitle">Check back every day for new deals near you</h3>
        </div>
      </div>
    </div>
  );
};

export default Register;
