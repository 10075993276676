class CurrentLocationOverlay extends google.maps.OverlayView {
  bounds;

  lat: number;

  lng: number;

  map: google.maps.Map;

  marker;

  constructor({ map, lat, lng }) {
    super();

    this.map = map;
    this.lat = lat;
    this.lng = lng;
    this.bounds = new google.maps.LatLngBounds(new google.maps.LatLng({ lat, lng }), new google.maps.LatLng({ lat, lng }));

    this.setMap(map);
  }

  onAdd() {
    const currentLocationMarker = document.createElement('button');

    currentLocationMarker.classList.add('marker', 'location-marker');
    currentLocationMarker.style.position = 'absolute';
    currentLocationMarker.setAttribute('type', 'button');
    currentLocationMarker.innerHTML = '<div class="icon-wrapper"><i class="material-icons icon">location_on</i></div>';

    this.marker = currentLocationMarker;

    const panes = this.getPanes();
    if (panes) {
      panes.overlayLayer.appendChild(currentLocationMarker);
      panes.overlayMouseTarget.appendChild(currentLocationMarker);
    }
  }

  draw() {
    const overlayProjection = this.getProjection();
    const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
    const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

    this.marker.style.left = `${sw.x}px`;
    this.marker.style.top = `${ne.y}px`;
  }

  onRemove() {
    this.marker.parentNode.removeChild(this.marker);
    this.marker = null;
  }
}

export default CurrentLocationOverlay;
