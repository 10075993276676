import React, { memo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Offer } from 'types/models';
import paths from 'config/paths';
import { useHoveredOfferContext } from 'lib/contexts/hovered-offer';

// Types

type Props = {
  offer: Offer;
};

const ListItem = (props: Props) => {
  const { offer } = props;

  const {
    id: offerId,
    title,
    address,
    telephone,
    description,
    cashbackDescription,
    image,
  } = offer;

  const { setHoveredOffer, hoveredOffer } = useHoveredOfferContext();

  const handleSetHovered = () => {
    setHoveredOffer(offer);
  };

  const handleUnsetHovered = () => {
    setHoveredOffer(null);
  };

  return (
    <li
      className={classnames('list-item', { hovered: hoveredOffer?.id === offerId })}
      onMouseEnter={handleSetHovered}
      onMouseLeave={handleUnsetHovered}
    >
      <Link to={paths.offer.replace(':id', offerId)} className="list-item-link">
        <div className="image" style={{ backgroundImage: `url(${image})` }}>
          <button type="button" className={classnames('heart')}>
            Serduszko
          </button>
        </div>
        <div className="data">
          <p className="cashback-info">
            <span className="icon icon-tag" />
            {cashbackDescription}
          </p>
          <div className="content">
            <h2 className="title">{title}</h2>
            <ul className="contact-information">
              <li>
                <span className="icon icon-location-pin" />
                {address}
              </li>
              <li>
                <span className="icon icon-phone" />
                {telephone}
              </li>
            </ul>
            <p className="description">{description}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default memo(ListItem);
