import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  heartWrapper: {
    background: 'transparent',
    transform: 'translateY(1px)',
  },
  heartActive: {
    color: '#ff154a',
  },
  heartInactive: {
    color: '#c4d4d3',
  },
}));
