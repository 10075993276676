import update from 'immutability-helper';

import { LayoutState } from 'store/types/states';
import { LayoutActionTypes } from 'store/types/actions';

const initialState = {
  refs: {
    header: null,
    filters: null,
    map: null,
    offers: null,
  },
  dimensions: {},
};

function layoutReducer(state: LayoutState = initialState, action): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.updateRef:
      return update(state, {
        refs: {
          [action.payload.refName]: { $set: action.payload.ref },
        },
      });
    case LayoutActionTypes.updateDimensions:
      return update(state, {
        dimensions: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default layoutReducer;
