import React, { memo } from 'react';

import Grid from '@material-ui/core/Grid';

import Weekday from './weekday';

type Props = {
  openingHours: {
    from: number;
    to: number;
    day: string;
  }[];
};

const OpeningHours = (props: Props) => {
  const { openingHours } = props;

  return (
    <Grid item xs={5} className="subsection-opening-hours">
      <h4 className="section-header">Opening hours</h4>
      <ul className="opening-hours">
        {openingHours.map(({ from, to, day }) => (<Weekday from={from} to={to} day={day} key={day} />))}
      </ul>
    </Grid>
  );
};

export default memo(OpeningHours);
