import { GridSize } from 'types';

export const getGridSizeLabel = (value) => {
  switch (value) {
    case 0:
      return 'Tiny';
    case 1:
      return 'Small';
    case 2:
      return 'Medium';
    case 3:
      return 'Big';
    case 4:
      return 'Large';
    default:
      return '';
  }
};

const gridValues = [
  {
    numberValue: 0,
    typeValue: GridSize.tiny,
  },
  {
    numberValue: 1,
    typeValue: GridSize.small,
  },
  {
    numberValue: 2,
    typeValue: GridSize.medium,
  },
  {
    numberValue: 3,
    typeValue: GridSize.big,
  },
  {
    numberValue: 4,
    typeValue: GridSize.large,
  },
];

export const sliderMarks = [
  {
    value: 0,
    // label: 'Tiny',
  },
  {
    value: 1,
    // label: 'Small',
  },
  {
    value: 2,
    // label: 'Medium',
  },
  {
    value: 3,
    // label: 'Big',
  },
  {
    value: 4,
    // label: 'Large',
  },
];

export const gridSizeTypeToNumber = (typeValue: GridSize): number => {
  const maybeFound = gridValues.find((gridValue) => gridValue.typeValue === typeValue);

  if (maybeFound) return maybeFound.numberValue;

  return 2;
};

export const gridSizeNumberToType = (numberValue: number): GridSize => {
  const maybeFound = gridValues.find((gridValue) => gridValue.numberValue === numberValue);

  if (maybeFound) return maybeFound.typeValue;

  return GridSize.medium;
};
