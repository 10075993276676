import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Color } from 'types/styles';

type Props = {
  color: Color;
  type?: 'submit' | 'button';
  to?: string;
  fullWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: Props) => {
  const {
    color,
    to,
    children,
    fullWidth,
    type = 'submit',
    ...restProps
  } = props;

  // eslint-disable-next-line react/button-has-type
  const buttonOnly = <button type={type} className={classnames('main-button', `gradient-${color}`, { 'full-width': fullWidth })} {...restProps}>{children}</button>;

  return to
    ? <Link to={to}>{buttonOnly}</Link>
    : buttonOnly;
};

Button.defaultProps = {
  type: 'submit',
  to: '',
  fullWidth: false,
};

export default Button;
