import { useSelector } from 'react-redux';

import authSelector from 'lib/selectors/partials/auth';

export default () => {
  const {
    jwtToken,
    isAuthenticated,
  } = useSelector(authSelector);

  return {
    jwtToken,
    isAuthenticated,
  };
};
