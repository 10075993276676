import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ViewType, GridSize } from 'types';
import { SettingsActionTypes } from 'store/types/actions';
import LayoutWrapper from 'common/layout-wrapper/layout-wrapper';
import IconButton from 'common/icon-button/icon-button';

import {
  gridSizeTypeToNumber,
  gridSizeNumberToType,
} from './helpers';
import { useStyles } from './styles';

import SliderWrapper from './slider-wrapper';

type Props = {
  viewType: ViewType;
  withMap: boolean;
  gridSize: GridSize;
};

const ViewSettings = (props: Props) => {
  const {
    viewType,
    withMap,
    gridSize,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles({});

  const handleChangeViewType = (type) => () => {
    dispatch({
      type: SettingsActionTypes.updateViewType,
      payload: type,
    });
  };

  const handleChangeWithMap = (event) => {
    dispatch({
      type: SettingsActionTypes.updateWithMap,
      payload: event.target.checked,
    });
  };

  const handleGridSizeChange = (event, newValue) => {
    if (newValue === gridSizeTypeToNumber(gridSize)) return;

    dispatch({
      type: SettingsActionTypes.updateGridSize,
      payload: gridSizeNumberToType(newValue),
    });
  };

  return (
    <div id="view-settings" className={classes.viewSettingsWrapper}>
      <LayoutWrapper>
        <div className={classes.viewSettingsContainer}>
          <Grid container>
            <Grid item>
              <IconButton
                isActive={viewType === ViewType.list}
                icon="menu"
                onClick={handleChangeViewType(ViewType.list)}
              />
            </Grid>
            <Grid item>
              <IconButton
                isActive={viewType === ViewType.grid}
                icon="grid"
                onClick={handleChangeViewType(ViewType.grid)}
              />
            </Grid>

            {viewType === ViewType.grid && (
              <SliderWrapper
                title="Cube size"
                value={gridSizeTypeToNumber(gridSize)}
                onChange={handleGridSizeChange}
              />
            )}
          </Grid>

          <FormControlLabel
            control={(
              <Checkbox
                id="taxable"
                value={withMap}
                checked={withMap}
                onChange={handleChangeWithMap}
                color="primary"
              />
            )}
            label={(
              <div className={classes.mapLabel}>
                <span className={classnames('icon-map', classes.mapIcon)} />
                <h5 className="label">Map</h5>
              </div>
            )}
            labelPlacement="start"
          />
        </div>
      </LayoutWrapper>
    </div>
  );
};

export default ViewSettings;
