import update from 'immutability-helper';

import { ViewType, GridSize } from 'types';
import { SettingsState } from 'store/types/states';
import { SettingsActionTypes } from 'store/types/actions';

const initialState = {
  viewType: ViewType.list,
  withMap: true,
  gridSize: GridSize.medium,
  perPage: 10,
  page: 0,
};

function settingsReducer(state: SettingsState = initialState, action): SettingsState {
  switch (action.type) {
    case SettingsActionTypes.updateViewType:
      return update(state, {
        viewType: { $set: action.payload },
      });
    case SettingsActionTypes.updateWithMap:
      return update(state, {
        withMap: { $set: action.payload },
      });
    case SettingsActionTypes.updateGridSize:
      return update(state, {
        gridSize: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default settingsReducer;
