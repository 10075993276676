import React, { useState, useReducer } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import logo from 'assets/images/logo.png';

import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';
import TextField from 'common/form/text-field';
import PasswordField from 'common/form/password-field';
import Button from 'common/form/button';
import Separator from 'common/content/separator';
import { FieldSize, Color } from 'types/styles';
import paths from 'config/paths';
import LoadingWrapper from 'common/loading-wrapper/loading-wrapper';
import useLoggedIn from 'lib/hooks/use-logged-in';
import { login } from 'store/actions/auth';

type FieldValuesState = {
  telephoneNumber: string;
  password: string;
};

type ErrorsState = {
  telephoneNumber: string | null;
  password: string | null;
};

const Login = () => {
  useLoggedIn();
  const dispatch = useDispatch();
  const [{
    telephoneNumber: telephoneNumberError,
    password: passwordError,
  }, setErrors] = useReducer<BasicReducer<ErrorsState>>(basicReducer, {
    telephoneNumber: 'There is problem with cellphone',
    password: null,
  });
  const [{
    telephoneNumber,
    password,
  }, setFieldValues] = useReducer<BasicReducer<FieldValuesState>>(basicReducer, {
    telephoneNumber: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const onCaptchaChange = (value) => {
    // console.log(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    await dispatch(login(telephoneNumber, password));

    setIsLoading(false);
  };

  const backgroundUrl = 'https://images2.alphacoders.com/902/thumb-1920-902946.png'; // TODO: change

  return (
    <div className="welcome-page login-page">
      <div className="content">
        <div className="inner">
          <a href="http://www.truly360.com" className="logo">
            <img src={logo} alt="Truly" />
          </a>

          <h2 className="title">Sign in to Truly360</h2>

          <LoadingWrapper loading={isLoading}>
            <form onSubmit={handleSubmit}>
              <div className="field-wrapper">
                <TextField
                  value={telephoneNumber}
                  onChange={(newValue) => setFieldValues({ telephoneNumber: newValue })}
                  label="Cell phone number"
                  size={FieldSize.fullWidth}
                  error={telephoneNumberError}
                />
              </div>

              <div className="field-wrapper small-margin">
                <PasswordField
                  value={password}
                  onChange={(newValue) => setFieldValues({ password: newValue })}
                  label="Password"
                  size={FieldSize.fullWidth}
                  error={passwordError}
                />
              </div>

              <div className="field-wrapper">
                <p className="reset-password">
                  <Link to={paths.resetPassword}>Reset password</Link>
                </p>
              </div>

              <div className="field-wrapper center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                  onChange={onCaptchaChange}
                />
              </div>

              <div className="login-action">
                <Button color={Color.blue} type="submit" fullWidth>
                  Sign in
                </Button>
                <Separator label="or" />
                <Button color={Color.grey} type="button" to={paths.register} fullWidth>
                  Create an account
                </Button>
              </div>
            </form>
          </LoadingWrapper>
        </div>
      </div>
      <div className="background" style={{ backgroundImage: `url('${backgroundUrl}'` }}>
        <div className="text">
          <h1 className="title">Discover top restaurants, spas, things to do & more</h1>
          <h3 className="subtitle">Check back every day for new deals near you</h3>
        </div>
      </div>
    </div>
  );
};

export default Login;
