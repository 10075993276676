import { AxiosResponse } from 'axios';
import axiosInstance from './axios-instance';

import offersMock from './offers-mock';

const URL = {
  offer: '',
};

const offerMock = {
  id: 1,
  logo: 'https://logosolusa.com/wp-content/uploads/parser/Random-Logo-1.png',
  title: 'Super oferta',
  address: 'Adres oferty',
  description: 'Casa Flores Mexican Restaurants are family owned and operated establishments which offer a warm and friendly atmosphere to those who visit each location.',
  cashback: 6,
  cashbackDescription: '23% Cashback of your Entire Bill',
  image: 'https://images2.alphacoders.com/902/thumb-1920-902946.png',
  telephone: '555 503 032',
  category: 'restaurant',
  location: {
    lat: 51.77320,
    lng: 19.50175,
  },
  isFavourite: false,
  website: 'http://www.google.pl',
  rating: 4.23,
  votes: 1583,
  openingHours: [{
    day: 'Monday',
    from: 12,
    to: 16,
  }, {
    day: 'Tuesday',
    from: 12,
    to: 16,
  }, {
    day: 'Wednesday',
    from: 12,
    to: 16,
  }, {
    day: 'Thursday',
    from: 12,
    to: 16,
  }],
};


// Promise<AxiosResponse<any>>
export default {
  fetchOffer: async (offerId): Promise<any> => new Promise((resolve) => resolve({ data: offerMock })), // axiosInstance.get(URL.offer, value),
  fetchOffers: async (filters, page): Promise<any> => new Promise((resolve) => {
    setTimeout(() => {
      if (page < 3) {
        resolve({
          data: [
            offersMock[(page - 1) * 6],
            offersMock[(page - 1) * 6 + 1],
            offersMock[(page - 1) * 6 + 2],
            offersMock[(page - 1) * 6 + 3],
            offersMock[(page - 1) * 6 + 4],
            offersMock[(page - 1) * 6 + 5],
          ],
        });
      } else {
        resolve({
          data: [],
        });
      }
    }, 1000);
  }),
  setOfferFavourite: async (offerId, newIsFavourite): Promise<any> => new Promise((resolve) => {
    const offer = offersMock.find(({ id }) => id === +offerId);

    Object.assign(offer, {
      isFavourite: newIsFavourite,
    });

    resolve({
      data: offer,
    });
  }),
};
