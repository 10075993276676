import React, { memo } from 'react';

import Grid from '@material-ui/core/Grid';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';

type Props = {
  address: string;
  telephone: string;
  website: string;
};

const Contact = (props: Props) => {
  const {
    address,
    telephone,
    website,
  } = props;

  return (
    <Grid item xs={7} className="subsection-contact">
      <h4 className="section-header">Contact</h4>
      <ul className="contact-details">
        <li>
          <div className="icon">
            <RoomIcon />
          </div>
          <p>{address}</p>
        </li>
        <li>
          <div className="icon">
            <PhoneIcon />
          </div>
          <p><a href={`tel:${telephone}`}>{telephone}</a></p>
        </li>
        <li>
          <div className="icon">
            <LanguageIcon />
          </div>
          <p><a href={website}>{website}</a></p>
        </li>
      </ul>
    </Grid>
  );
};

export default memo(Contact);
