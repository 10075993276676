import React, { memo } from 'react';
import classnames from 'classnames';

import { Offer } from 'types/models';
import LayoutWrapper from 'common/layout-wrapper/layout-wrapper';

import ListItem from './item';

// Types

type Props = {
  offers: Offer[];
};

const List = (props: Props) => {
  const { offers } = props;

  return (
    <LayoutWrapper>
      <ul className={classnames('offers', 'list-view')}>
        {offers.map((offer) => (
          <ListItem key={offer.id} offer={offer} />
        ))}
      </ul>
    </LayoutWrapper>
  );
};

export default memo(List);
