import React from 'react';

type Props = {
  from: number;
  to: number;
  day: string;
};

const Weekday = (props: Props) => {
  const { from, to, day } = props;

  return (
    <li>
      <span className="weekday">{day}</span>
      <span className="hours">{`${from} - ${to}`}</span>
    </li>
  );
};

export default Weekday;
