import { createContext, useContext } from 'react';

import { Offer } from 'types/models';

type HoveredOfferContextType = {
  hoveredOffer: Offer;
  setHoveredOffer: (offer: Offer) => void;
};

export const HoveredOfferContext = createContext<HoveredOfferContextType>({
  hoveredOffer: null,
  setHoveredOffer: (offer) => {},
});

export const useHoveredOfferContext = () => useContext(HoveredOfferContext);
