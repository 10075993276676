import React, { useReducer, MouseEvent } from 'react';
import classnames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { FieldSize } from 'types/styles';
import basicReducer, { BasicReducer } from 'lib/reducers/basic-reducer';

// Types

type Option = any; // string | number | { label: string; value: number | string; }

type OptionProps = {
  option: Option;
  isTextValue: boolean;
  handleChange: (event: MouseEvent<HTMLButtonElement>, option: Option) => void;
  value: Option;
};

const Option = (props: OptionProps) => {
  const {
    option,
    isTextValue,
    handleChange,
    value,
  } = props;

  const optionValue = isTextValue ? option : option.value;
  const optionLabel = isTextValue ? option : option.label;

  return (
    <li key={optionValue} className={classnames('option', { current: optionValue === value })}>
      <button type="button" onClick={(event) => handleChange(event, option)} className="option-button">
        {optionLabel}
      </button>
    </li>
  );
};

// Select

type Props = {
  label: string;
  noValueLabel: string;
  name: string;
  value: Option;
  onChange: (name: string, value: Option) => void;
  size: FieldSize;
  fetchOptionsMethod: (value) => Promise<Option>;
  isDestination?: boolean;
  currentLocationLabel?: string;
  isTextValue?: boolean;
};

type State = {
  isActivated: boolean;
  text: string;
  options: Option[];
};

const SearchSelectField = (props: Props) => {
  const {
    label,
    noValueLabel,
    name,
    value,
    onChange,
    size,
    currentLocationLabel = '',
    fetchOptionsMethod,
    isDestination = false,
    isTextValue = false,
  } = props;

  const [{
    isActivated,
    text,
    options,
  }, setState] = useReducer<BasicReducer<State>>(basicReducer, {
    isActivated: false,
    text: '',
    options: [],
  });

  // Actions

  const fetchOptions = async () => {
    const data = await fetchOptionsMethod(text);

    setState({
      options: data,
    });
  };

  const [debouncedFetchOptions] = useDebouncedCallback(fetchOptions, 200);

  // Handlers

  const handleChange = (event, option) => {
    if (!isTextValue && option.value === value) {
      event.stopPropagation();
      return;
    }

    setState({
      isActivated: false,
      text: '',
    });

    const finalValue = (() => {
      if (isDestination && option === 'current-location') return option;

      return isTextValue ? option : options.find(({ value: optionValue }) => optionValue === option.value);
    })();

    onChange(name, finalValue);
  };

  const handleDeactivate = () => {
    setState({
      isActivated: false,
      text: '',
    });
  };

  const handleActivate = () => {
    setState({
      isActivated: true,
      text: '',
    });
  };

  const handleChangeText = (event) => {
    const { target: { value: searchValue } } = event;

    setState({
      text: searchValue,
    });

    if (searchValue.length > 2) debouncedFetchOptions();
  };

  // Helpers

  const getTextValue = () => {
    if (isActivated) return text;
    if (value === 'current-location') return currentLocationLabel;

    return isTextValue ? value : value?.label;

    // return options.find(({ value: optionValue }) => value === optionValue)?.label || '';
  };

  return (
    <ClickAwayListener onClickAway={handleDeactivate}>
      <div className={classnames('field-common', 'select-field', 'select-search-field', size, { 'location-field': isDestination, choosen: !!value })}>
        <p className="label">{label}</p>

        <div className="field">
          <input
            type="text"
            value={getTextValue()}
            placeholder={noValueLabel}
            onChange={handleChangeText}
            onFocus={handleActivate}
          />
          <span className={classnames('icon', `icon-arrow-${isActivated ? 'up' : 'down'}`)} />
        </div>

        {(isActivated && (options.length > 0 || isDestination)) && (
          <div className="options-wrapper">
            <ul className="options">
              {isDestination && (
                <li key={value} className={classnames('option', 'current-location', { current: value === 'current-location' })}>
                  <button type="button" onClick={(event) => handleChange(event, 'current-location')} className="option-button option-current-location">
                    <span className="label">{currentLocationLabel}</span>
                    <span className="icon icon-location-pin" />
                  </button>
                </li>
              )}
              {options.map((option) => (
                <Option
                  key={isTextValue ? option : option.value}
                  option={option}
                  isTextValue={isTextValue}
                  handleChange={handleChange}
                  value={value}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

SearchSelectField.defaultProps = {
  isDestination: false,
  currentLocationLabel: '',
  isTextValue: false,
};

export default SearchSelectField;
