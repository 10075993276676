import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type StyleProps = {
  offerDimensions: {
    paddingTop: number;
  };
};

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  backgroundWrapper: {
    paddingTop: ({ offerDimensions }) => offerDimensions?.paddingTop || 0,
  },
}));
