import { useHistory } from 'react-router-dom';

import paths from 'config/paths';
import useAuth from './use-auth';

export default () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    history.push(paths.main);
  }
};
